import React from 'react';
import { Link } from 'react-router-dom';
import M from 'materialize-css';
import './Nav.css'

export class Nav extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }
    componentDidMount() {
        this.instance = M.Sidenav.init(this.ref.current);
	}
	componentWillUnmount() {
		this.instance.destroy();
    }
    render() {
        return (<React.Fragment>
            <nav className="white darken-4">
                <div className="container">
                    <div className="nav-wrapper">
                        <button data-target="sidenav" className="sidenav-trigger show-on-large browser-default" style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            padding: 0
                        }}>
                            <i className="material-icons">menu</i>
                        </button>
                    </div>
                </div>
            </nav>

            <ul ref={this.ref} className="sidenav sidenav-close" id="sidenav">
                {/*<li><Link to="/"><i className="small material-icons">home</i>Home</Link></li>*/}
                <li><Link to="/vacations"><i className="small material-icons">beach_access</i>Vacations</Link></li>
                <li><Link to="/bar"><i className="small material-icons">local_drink</i>It&apos;s 5 o&apos;clock somewhere</Link></li>
            </ul>
        </React.Fragment>);
    }
}