import React, { Suspense, lazy } from 'react';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch
} from 'react-router-dom';
import { Preloader } from './common/Preloader';
import { Nav } from './Nav';

import 'materialize-css/dist/css/materialize.min.css';
import './App.css';
import './bar/Bar.css';

import 'swiper/swiper-bundle.css';

const HomeRoute = lazy(() => import('./home/HomeRoute'));
const BarRoute = lazy(() => import('./bar/BarRoute'));
const VacationsRoute = lazy(() => import('./VacationsRoute'));

function App() {
    return (
        <Router>
            <Suspense fallback={<Preloader loading={true} />}>
                <Switch>
                    <Route path="/home">
                        <Nav />
                        <HomeRoute />
                    </Route>
                    <Route path="/vacations">
                        <Nav />
                        <VacationsRoute />
                    </Route>
                    <Route path="/bar/:tags?">
                        <Nav />
                        <BarRoute />
                    </Route>
                    <Redirect exact from="/" to="bar" />
                    <Redirect exact from="*" to="/" />
                </Switch>
            </Suspense>
        </Router>
    );
}

export default App;
